<template>
  <div id="directorEdit">
    <app-menu></app-menu>
    <div class="col-lg-10 offset-lg-1">
      <h3 class="text-center">{{ "Admin.menu.directors" | staticTranslation }}</h3>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="exampleSelect1">Vyberte ročník:</label>
            <select class="form-control" v-model="director.periodId" id="exampleSelect1">
              <option v-for="period in periods" v-bind:key="period.id" v-bind:value="period.id">
                {{ period.name.cs }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <p>Fotografii vkládajte při poměru stran 4:3</p>
            <img class="img-fluid" width="50%" :src="image"/>
            <input type="file" class="form-control-file" accept="image/jpeg" @change="selectImage"
                   aria-describedby="fileHelp">
            <small id="fileHelp" class="form-text text-muted"></small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <h4 class="text-center">Čestina</h4>
          <form>
            <div class="form-group">
              <label for="formNameCz">Název dramaturga:</label>
              <input type="text" class="form-control" id="formNameCz" v-model="director.name.cs"
                     placeholder="Název dramaturga">
            </div>
            <div class="form-group">
              <label for="TextareaCz">Popis:</label>
              <textarea class="form-control" id="TextareaCz" v-model="director.description.cs" rows="3"></textarea>
            </div>
          </form>
        </div>
        <div class="col-lg-6">
          <h4 class="text-center">Angličtina</h4>
          <form>
            <div class="form-group">
              <label for="formNameEn">Název dramaturga:</label>
              <input type="text" class="form-control" id="formNameEn" v-model="director.name.en"
                     placeholder="Název dramaturga">
            </div>
            <div class="form-group">
              <label for="TextareaEn">Popis:</label>
              <textarea class="form-control" id="TextareaEn" v-model="director.description.en" rows="3"></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="form-check form-switch text-center pt-lg-4">
        <input id="visible" class="form-check-input" type="checkbox" v-model="director.visible">
        <label class="form-check-label" for="visible">Viditelný</label>
      </div>
      <div class="text-center pt-lg-4">
        <send-button
            :send="create"
            text="Uložit dramaturga"
            :response="error"
        />
      </div>
    </div>
    <error-modal :message="error"/>
  </div>
</template>

<script>
import axios from "@/api/axiom-instance";
import AdminMenu from "@/components/admin/AdminMenu";
import config from '@/config'
import ErrorModal from "@/components/admin/ErrorModal";
import SendButton from "@/components/admin/SendButton";

export default {
  name: "ProgramDirectorEdit",
  components: {
    'app-menu': AdminMenu,
    'error-modal': ErrorModal,
    'send-button': SendButton
  },
  data() {
    return {
      director: {name: {}, description: {}},
      periods: [],
      image: "",
      error: null
    }
  },
  created() {
    axios
        .get("/period/list")
        .then((success) => {
          this.periods = success.data
          if (this.$route.params.id != null) {
            this.load(this.$route.params.id)
          }
        }).catch((error) => this.error = error)
  },
  methods: {
    create: function () {
      axios.post("/director", this.director).then((success) => {
        axios.post("/image", {name: success.data.id, type: "directors", data: this.image}).then(() => {
          this.$router.push({path: "/admin/program/director"})
        }).catch((error) => this.error = error)
      }).catch((error) => this.error = error)
    },
    load: function (id) {
      axios
          .get("/director", {params: {id: id}})
          .then((success) => {
            this.director = success.data;
            this.image = config.API_URL + "/image?type=directors&name=" + id
          }).catch((error) => this.error = error)
    },
    selectImage: function (e) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = e => {
        this.image = e.target.result
      }
    }
  }
}
</script>

<style scoped>
#directorEdit {
  padding-bottom: 3em;
  background-color: white;
  min-height: 100vh;
}
</style>
